import React, { type ReactNode, useCallback, useMemo, useRef } from 'react';

import uuid from 'uuid/v4';

import {
	type AIEventsInstrumentationConfig,
	AIEventsInstrumentationContext,
	type AIEventsInstrumentationContextType,
} from '../context';
import { AISessionState } from '../events';

type AIEventsInstrumentationProviderProps = {
	config: AIEventsInstrumentationConfig;
};

export const defaultConfigValues: Pick<
	AIEventsInstrumentationConfig,
	'actionSubjectId' | 'isAIFeature'
> = {
	isAIFeature: 1,
	actionSubjectId: 'client',
};

const validateAIEventsConfig = (config: AIEventsInstrumentationConfig) => {
	if (!config.aiFeatureName.match(/^[a-z][A-Za-z]*$/)) {
		throw new Error('[@atlassian/ai-analytics]: please set aiFeatureName in camelCase');
	}
};

export const AIEventsInstrumentationProvider = ({
	children,
	config,
}: {
	children: ReactNode;
} & AIEventsInstrumentationProviderProps) => {
	const singleInstrumentationIDRef = useRef<string | undefined>(undefined);
	const aiSessionStateRef = useRef(AISessionState.unset);

	const setAISessionState = useCallback((nextState: AISessionState) => {
		aiSessionStateRef.current = nextState;
	}, []);

	const getAISessionState = useCallback(() => {
		return aiSessionStateRef.current;
	}, []);

	const refreshSingleInstrumentationID = useCallback(() => {
		singleInstrumentationIDRef.current = uuid();
	}, []);

	const getSingleInstrumentationID = useCallback(() => {
		return singleInstrumentationIDRef.current;
	}, []);

	const contextValue = useMemo<AIEventsInstrumentationContextType>(() => {
		validateAIEventsConfig(config);
		return {
			config: {
				...defaultConfigValues,
				...config,
			},
			refreshSingleInstrumentationID,
			getSingleInstrumentationID,
			setAISessionState,
			getAISessionState,
		};
	}, [
		config,
		refreshSingleInstrumentationID,
		getSingleInstrumentationID,
		setAISessionState,
		getAISessionState,
	]);

	return (
		<AIEventsInstrumentationContext.Provider value={contextValue}>
			{children}
		</AIEventsInstrumentationContext.Provider>
	);
};
