import React from 'react';

import { AISummaryStreamingState } from '@atlassian/ai-summary';

import { ExperienceSuccess, QUICK_SUMMARY_EXPERIENCE } from '@confluence/experience-tracker';

type ExperienceStreamingSuccessProps = {
	state: AISummaryStreamingState;
};

export const ExperienceStreamingSuccess = ({ state }: ExperienceStreamingSuccessProps) => {
	if (
		state === AISummaryStreamingState.FinalResponse ||
		state === AISummaryStreamingState.FollowUp
	) {
		return <ExperienceSuccess name={QUICK_SUMMARY_EXPERIENCE} />;
	}

	return null;
};
