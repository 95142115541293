import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Stack } from '@atlaskit/primitives';

import { FollowUpButton } from '@atlassian/conversation-assistant-ui-components';
import type { FollowUpObject } from '@atlassian/ai-summary';
import { usePublish } from '@atlassian/conversation-assistant-pubsub';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

export type QuickSummaryFollowUpComponentProps = {
	followUpContent: FollowUpObject[];
	summaryContent: string;
	contentPath: string;
	setIsOpen: (open: boolean) => void;
};

const i18n = defineMessages({
	quickSummaryUserQuery: {
		id: 'quick-summary.follow-up.initial-human-prompt',
		defaultMessage: 'Summarize this {link}',
		description:
			'When user decides to continue conversation after summarizing the page content, this message will be shown in the chatbox as the original prompt that user sent',
	},
});

export const QuickSummaryFollowUpComponent: FC<QuickSummaryFollowUpComponentProps> = ({
	followUpContent,
	summaryContent,
	contentPath,
	setIsOpen,
}) => {
	const publish = usePublish('ai-mate');
	const { formatMessage } = useIntl();

	const { trackAIResultAction } = useAIEventsInstrumentation();

	const contentLink = `${window.location.origin}${contentPath}`;

	return (
		<Stack space="space.100">
			{followUpContent.map((followUp, index) => (
				<FollowUpButton
					key={followUp.text}
					followUpIndex={index}
					followUpText={followUp.text}
					followUpType={followUp.type}
					experienceId="page-summaries"
					onClick={() => {
						trackAIResultAction('followUpQuestionClick');
						publish({
							type: 'chat-new',
							source: 'page-summary',
							data: {
								name: followUp.text.substring(0, 30),
								dialogues: [
									{
										human_message: {
											content: formatMessage(i18n.quickSummaryUserQuery, {
												link: contentLink,
											}),
										},
										agent_message: { content: summaryContent },
									},
								],
								prompt: followUp.text,
							},
						});

						setIsOpen(false);
					}}
				/>
			))}
		</Stack>
	);
};
