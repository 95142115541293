import type { FC } from 'react';
import React, { useEffect, useRef, useContext } from 'react';
import Markdown from 'markdown-to-jsx';
import { useIntl } from 'react-intl-next';

import { useResizingHeight } from '@atlaskit/motion';
import { Box, Stack } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	AISummaryStreamingState,
	type StreamError,
	useAISummaryStreaming,
	useConfluencePageAri,
} from '@atlassian/ai-summary';
import { AIStreamLoading } from '@atlassian/ai-components';
import { usePopupDwellTime } from '@atlassian/popup-dwell-time';
import type { AIEventsInstrumentationConfig } from '@atlassian/ai-analytics';
import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';

import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { ExperienceTrackerContext, QUICK_SUMMARY_EXPERIENCE } from '@confluence/experience-tracker';

import { QUICK_SUMMARY_DIALOG_INTERACTION_METRIC } from '../perf.config';
import { SmartsThemedContainer } from '../shared-components/SmartsThemedContainer';
import { useQuickSummaryAnalyticsProperties } from '../hooks/useQuickSummaryAnalyticsProperties';
import { AISummaryFooter } from '../shared-components/AISummaryFooter';
import { SmartsSummaryErrorComponent } from '../shared-components/SmartsSummaryErrorComponent';
import { QuickSummaryFollowUpContainer } from '../QuickSummaryFollowUpContainer';
import {
	MAX_SUMMARY_HEIGHT,
	SummaryPopupScrollableBody,
} from '../shared-components/SummaryPopupScrollableBody';
import { FeedbackCollectorPropertiesProvider } from '../shared-components/FeedbackCollectorPropertiesProvider';
import { QUICK_SUMMARY_FEEDBACK_ENTRYPOINT_ID } from '../feedbackEntrypointIds';

import { ExperienceStreamingSuccess } from './ExperienceStreamingSuccess';

const aiAnalyticsConfig: AIEventsInstrumentationConfig = {
	product: 'confluence',
	subproduct: 'confluence',
	source: 'confluencePage',
	aiFeatureName: 'quickSummary',
	proactiveGeneratedAI: 0,
	userGeneratedAI: 1,
};

export type QuickSummaryStreamingComponentProps = {
	contentId: string;
	lastModifiedDate: string;
	source: string;
	contentPath: string;
	contentType: string;
	setIsOpen: (open: boolean) => void;
};

const QuickSummaryComponent: FC<QuickSummaryStreamingComponentProps> = ({
	contentId,
	lastModifiedDate,
	source,
	contentPath,
	contentType,
	setIsOpen,
}) => {
	const summaryRef = useRef<HTMLDivElement | null>(null);
	const { getDwellTime } = usePopupDwellTime(summaryRef);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { locale } = useIntl();
	const contentLastUpdated = Math.floor(new Date(lastModifiedDate).getTime() / 1000);

	const experienceTracker = useContext(ExperienceTrackerContext);

	const {
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultError,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();

	const { isRovoEnabled: isRovoEnabledSessionData, cloudId } = useSessionData();

	const { pageAri } = useConfluencePageAri(cloudId, contentId, contentType);

	// This will be removed together with `confluence.frontend.use-rovo-entitlement-check`
	// the entitlement check will eventually be based on `isRovoEnabled` only, and no feature flag changes
	const isFollowUpFlagEnabled = useBooleanFeatureFlag(
		'platform.ai-page-summary-show-follow-ups_6pb6',
	);

	const isUseRovoEntitlementFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.use-rovo-entitlement-check',
	);
	const isRovoEnabled = isUseRovoEntitlementFFEnabled && isRovoEnabledSessionData;

	const isPageSummaryFollowUpEnabled = isFollowUpFlagEnabled || isRovoEnabled;

	const { analyticsProperties } = useQuickSummaryAnalyticsProperties(contentLastUpdated);

	const isStreamingDone = useRef(false);

	const setIsStreamingDone = () => {
		isStreamingDone.current = true;
	};

	const onStart = () => {
		experienceTracker.start({
			name: QUICK_SUMMARY_EXPERIENCE,
			attributes: {
				contentId,
			},
		});
		QUICK_SUMMARY_DIALOG_INTERACTION_METRIC.start();
		trackAIInteractionInit();
	};

	const onError = (reason: StreamError) => {
		QUICK_SUMMARY_DIALOG_INTERACTION_METRIC.stop();
		trackAIResultError({
			aiErrorCode: reason.message.status_code!!,
			aiErrorMessage: reason.message.message_template,
		});
		setIsStreamingDone();
	};

	useEffect(() => {
		return () => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'dwelled',
					actionSubject: 'quickSummary',
					source,
					actionSubjectId: 'quickSummaryDialog',
					attributes: {
						contentId,
						dwellTime: getDwellTime(),
						isAIMateSummary: true,
					},
				},
			}).fire();
			if (!isStreamingDone.current) {
				trackAIInteractionDismiss();
			}
		};
		// we only want to run this effect on un-mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { content, state, followUpContent, error } = useAISummaryStreaming({
		ari: pageAri ?? '',
		agentNamedId: 'page_summaries_agent',
		product: 'confluence',
		experienceId: 'page-summaries',
		locale,
		summaryStyle: 'medium',
		summaryOutputMimetype: 'text/markdown',
		delayChunkUpdate: { gap: 10, time: 20 },
		onStart,
		onError,
		onDone: setIsStreamingDone,
		featureFlags: {
			showFollowUps: isPageSummaryFollowUpEnabled,
		},
	});

	useEffect(() => {
		if (state === AISummaryStreamingState.FinalResponse && content) {
			const summaryHeight = summaryRef.current?.scrollHeight ?? 0;
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'quickSummaryDialog',
					attributes: {
						contentId,
						summaryLocale: 'en-US',
						contentLastUpdated,
						summaryHeight,
						maxSummaryHeight: MAX_SUMMARY_HEIGHT,
						isAIMateSummary: true,
					},
				},
			}).fire();
			trackAIResultView();
			QUICK_SUMMARY_DIALOG_INTERACTION_METRIC.stop();
		}
	}, [
		createAnalyticsEvent,
		contentId,
		contentLastUpdated,
		content,
		state,
		isPageSummaryFollowUpEnabled,
		trackAIResultView,
	]);

	const renderPopupContent = () => {
		switch (state) {
			case AISummaryStreamingState.Initialization:
				return <AIStreamLoading />;
			case AISummaryStreamingState.Error:
				return (
					<SmartsSummaryErrorComponent
						analyticsProperties={analyticsProperties}
						errorFromApolloOrStream={error}
						contentId={contentId}
						experienceName={QUICK_SUMMARY_EXPERIENCE}
					/>
				);
			case AISummaryStreamingState.AnswerPart:
			case AISummaryStreamingState.FinalResponse:
			case AISummaryStreamingState.FollowUp:
				return (
					<Stack>
						<Box padding="space.250">
							<Stack space="space.250">
								<SummaryPopupScrollableBody
									ref={summaryRef}
									data-testid="quick-summary-body-content"
								>
									<Markdown>{content}</Markdown>
								</SummaryPopupScrollableBody>
								<QuickSummaryFollowUpContainer
									content={content}
									contentPath={contentPath}
									setIsOpen={setIsOpen}
									isPageSummaryFollowUpEnabled={isPageSummaryFollowUpEnabled}
									followUpContent={followUpContent}
								/>
							</Stack>
						</Box>
						<AISummaryFooter
							analyticsProperties={analyticsProperties}
							contentId={contentId}
							summaryText={content}
							isHidden={state === AISummaryStreamingState.AnswerPart}
						/>
						<ExperienceStreamingSuccess state={state} />
					</Stack>
				);
		}
	};

	return (
		<SmartsThemedContainer loading={state === AISummaryStreamingState.Initialization}>
			<div {...useResizingHeight()}>{renderPopupContent()}</div>
		</SmartsThemedContainer>
	);
};

export const QuickSummaryStreamingComponent = (props: QuickSummaryStreamingComponentProps) => {
	return (
		<FeedbackCollectorPropertiesProvider entrypointId={QUICK_SUMMARY_FEEDBACK_ENTRYPOINT_ID}>
			<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
				<QuickSummaryComponent {...props} />
			</AIEventsInstrumentationProvider>
		</FeedbackCollectorPropertiesProvider>
	);
};
